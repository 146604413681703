import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  standalone: true,
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    return `${environment.apiConfigs.IMAGE_HOST}/${value}`;
  }
}
